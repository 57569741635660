/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Countdown from 'react-countdown';

import './SalesCountdown.style';

/** @namespace Pwa/Component/Countdown/SalesCountdown/SalesCountdown */
export class SalesCountdown extends PureComponent {
    static propTypes = {
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired
    };

    renderCountdown = ({
        days, hours, minutes, seconds, completed
    }) => {
        // If the countdown is completed, don't render anything
        if (completed) {
            return null;
        }

        return (
            <div block="SalesCountdown" elem="Block">
                <FontAwesomeIcon icon={ faExclamationTriangle } size="1x" color="#222" />
                <div>
                    Sale Ends in
                    { ' ' }
                    <strong>
                        { days }
                        { ' ' }
                        { days === 1 ? 'day' : 'days' }
                        { ' ' }
                        <span>
                            { String(hours).padStart(2, '0') }
                            :
                            { String(minutes).padStart(2, '0') }
                            :
                            { String(seconds).padStart(2, '0') }
                        </span>
                    </strong>
                </div>
            </div>
        );
    };

    render() {
        const { startDate, endDate } = this.props;

        // If start date or end date is empty, don't render the countdown
        if (!startDate || !endDate) {
            return null;
        }

        const now = new Date();
        const startTime = Date.parse(startDate);
        const endTime = Date.parse(endDate);

        // If start time or end time is not a number, don't render the countdown
        if (Number.isNaN(startTime) || Number.isNaN(endTime)) {
            console.error('Invalid date configuration:', { startDate, endDate });
            return null;
        }

        // If the current time is before the start date, don't render the countdown
        if (now < startTime) {
            return null;
        }

        return <Countdown date={ endTime } renderer={ this.renderCountdown } />;
    }
}

export default SalesCountdown;
